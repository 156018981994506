import React from 'react';
import RegularFade from './../components/animations/RegularFade';
import imageOne from '../assets/images/cyber-vulnerabilities-adv-man.png';

const CyberThreat: React.FC = () => {
    return (
        <div className="font-jakarta bg-custom-black min-h-screen">
            <div className="flex flex-col">
                <img src={imageOne} alt="Cyber vulnerabilities advanced man" className="w-full h-auto object-cover mb-4" />
            </div>
        </div>
    );
}

export default RegularFade(CyberThreat);
